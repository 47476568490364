<template>
   <div class="v-data-table  report bordered--table  theme--light">
    <template v-for="(item,index) in list">
        <v-simple-table class="report table table-bordered" v-if="type === 'all' || type === 'estimation'" :key="index">
          <tbody>
            <tr style="background: #354759!important; color: #fff;">
              <td :colspan="headers.length">
                <strong>{{ $t('customer') }}: {{ item.customername || '' }}</strong>
              </td>
            </tr>
          </tbody>
          <template>
            <tbody v-for="project in item.projects" :key="project.projectid">
              <tr style="background: #5bc0de !important; color: #fff;" v-if="timerFormSettings.project && project.projectid">
                <td :colspan="headers.length">
                  <strong>{{ $t('project') }}: {{ project.projectnumber || '' }}</strong>
                  <strong>{{ project.projectnumber ? ' - ' + project.projectname : project.projectname || '' }}</strong>
                </td>
              </tr>
              <tr v-if="project &&  project.hours && project.hours.length">
                <template v-for="(head, index) in headers">
                  <td v-if="filter.groupactivities ? groupByActivitySupportedHeaders.includes(head.name) : true" :width="!filter.groupactivities ? head.width : 100 / 3 + '%'" :class="head.class" :key="index"><strong>{{ $t(head.text) }}</strong></td>
                </template>
              </tr>
              <tr v-for="hour in project.hours" :key="hour.id">
                <template v-for="(head, index) in headers">
                  <td v-if="filter.groupactivities ? groupByActivitySupportedHeaders.includes(head.name) : true" :class="head.class" :key="index">
                    <!-- <template v-if="head.name === 'date'">{{ $formatter.formatDate(hour[head.name], 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY HH:mm') }}</template> -->
                    <!-- <template v-if="head.name === 'date'">{{ $formatter.formatDate(hour[head.name], 'DD.MM.YYYYTHH:mm:ss', 'DD.MM.YYYY') }}</template> -->
                     <template v-if="head.name === 'date'"> <span class="d-inline-block text-truncate"> {{ $formatter.formatDate(hour[head.name], '', userDetails.dateformat) }} </span> </template>
                    <template v-else-if="head.name === 'hours'">{{ hour[head.name] - hour['breakhr'] | replaceDotWithComma }}</template>
                    <template v-else-if="['breakhr', 'toinvoice'].includes(head.name)" >{{ hour[head.name] | replaceDotWithComma }}</template>
                    <!-- <template v-else-if="head.name === 'state'" >{{ getStateName(hour[head.name]) }}</template> -->
                    <template v-else-if="head.name === 'startEndTime'" >{{ hour.isabsence ? $t('notApplicableAbsence') : hour.start_date && hour.end_date ? $formatter.checkForIsSameDateTimeOrInvertedTimeRange(hour.start_date, hour.end_date) && $formatter.formatDate(hour.end_date, 'DD.MM.YYYYTHH:mm:ss', 'HH:mm') === '00:00' && hour.type === "working" ? $formatter.formatDate(hour.start_date, 'DD.MM.YYYYTHH:mm:ss', 'HH:mm') + ' - ' : getTime(hour) : '' }} </template>
                    <template v-else-if="head.name === 'worktype_name'" > {{ hour[head.name] ? `${hour.worktype_number || ''} - ${ hour[head.name] || ''}` : '' }} </template>
                    <template v-else>{{ hour[head.name] }}</template>
                  </td>
                </template>
              </tr>
              <tr style="background: rgb(255, 186, 192) !important;">
                <td :colspan="headers.length">
                  <strong>{{ $t('total') }}: <strong>{{ $formatter.getSumBy(project.hours, x => x.hours) | replaceDotWithComma }}</strong></strong> &nbsp;&nbsp;&nbsp;
                  <strong>{{ $t('totalToInvoice') }}: <strong>{{ $formatter.getSumBy(project.hours, x => x.toinvoice) | replaceDotWithComma }}</strong></strong>
                </td>
              </tr>
              <tr style="background: rgb(255, 186, 192) !important;" v-if="type === 'estimation'">
                <td :colspan="headers.length">
                <strong>{{ $t('estimation') }}: {{ project.estimationhrs | replaceDotWithComma }}</strong> &nbsp;&nbsp;&nbsp;
                <strong class="float-right">{{ $t('progress') }}: {{ project.progress || '0' | replaceDotWithComma }} %</strong>
                </td>
              </tr>
            </tbody>
          </template>
          <tbody>
            <tr style="background: #c9d7db !important;">
              <td :colspan="headers.length">
                <strong> {{ $t('customer') }}: {{ $t('total') }}: <strong> {{ calculateTotalHoursOfCustomerForAllProject(item.projects, 'hours') | replaceDotWithComma  }}</strong></strong> &nbsp;&nbsp;&nbsp;
                <strong>{{ $t('totalToInvoice') }}: <strong>{{ calculateTotalHoursOfCustomerForAllProject(item.projects, 'toinvoice') | replaceDotWithComma }} </strong></strong>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      <!-- </template> -->
    </template>
    <v-simple-table class="report table table-bordered" v-if="type === 'sum'">
      <template v-for="(item,index) in list">
        <tbody :key="index">
          <tr style="background: #354759!important; color: #fff;">
            <td colspan="2"><strong>{{ $t('customer') }}: {{ item.customername || '' }} </strong></td><!-- {{ item.projectnumber || '' }} -->
          </tr>
          <tr>
            <td style="width:70%"><strong>{{ $t('project') }}</strong></td>
            <td style="width:30%"><strong>{{ $t('total') }}</strong></td>
          </tr>
          <tr v-for="project in item.projects" :key="project.projectid">
            <td>{{ project.projectname }}</td>
            <td>{{ $formatter.getSumBy(project.hours, x => x.hours) - $formatter.getSumBy(project.hours, x => x.breakhr) | replaceDotWithComma }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-simple-table class="report table table-bordered" v-if="type === 'sum_both'">
      <template v-for="(item,index) in list">
        <tbody :key="index">
          <tr style="background: #354759!important; color: #fff;">
            <td colspan="4"><strong>{{ $t('customer') }}: {{ item.customername || '' }} </strong></td><!-- {{ item.projectnumber || '' }} -->
          </tr>
          <tr>
            <td style="width:20%"><strong>{{ $t('project') }}</strong></td>
            <td style="width:20%"><strong>{{ $t('total') }}</strong></td>
            <td colspan="1"><strong>{{ $t('estimation') }} </strong></td>
            <td colspan="1"><strong>{{ $t('progress') }}</strong></td>
          </tr>
          <tr v-for="project in item.projects" :key="project.projectid">
            <td>{{ project.projectname }}</td>
            <td>{{ $formatter.getSumBy(project.hours, x => x.hours) - $formatter.getSumBy(project.hours, x => x.breakhr) | replaceDotWithComma }}</td>
            <td>{{ project.estimationhrs }}</td>
            <td>{{ project.progress | replaceDotWithComma }} % </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>
<script>
export default {
  props: ['type', 'list', 'headers', 'filter', 'timerFormSettings'],
  data () {
    return {
      groupByActivitySupportedHeaders: ['worktype_name', 'hours', 'toinvoice']
    }
  },
  methods: {
    calculateTotalHoursOfCustomerForAllProject (projectHours, value) {
      var result = 0
      projectHours.forEach(project => {
        result += this.$formatter.getSumBy(project.hours, x => x[value])
      })
      return result
    },
    getCustomerOrdersHourTotal (allHours) {
      let total = 0
      if (allHours && allHours.length > 0) {
        for (const hour of allHours) {
          total += this.$formatter.getSumBy(hour.hours, x => x.hours)
        }
      }
      return total
    },
    getProjectEstimation (allActivities) {
      let total = 0
      if (allActivities && allActivities.length > 0) {
        for (const activities of allActivities) {
          total += activities.estimationhrs
        }
      }
      return total
    },
    getProjectCost (allActivities) {
      let total = 0
      if (allActivities && allActivities.length > 0) {
        allActivities.forEach(activity => {
          if (activity.hours && activity.hours.length > 0) {
            activity.hours.forEach(hour => {
              total += hour.project_cost
            })
          }
        })
      }
      return total
    },
    getProjectProgress (allActivities) {
      let total = 0
      if (allActivities && allActivities.length > 0) {
        for (const activities of allActivities.length) {
          total += activities.progress
        }
      }
      return total
    }
  }
}
</script>
